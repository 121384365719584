import {
	Box,
	Heading,
	ListItem,
	OrderedList,
	Stack,
	Text,
	VStack,
} from "@chakra-ui/react";
import * as React from "react";

export default function Terms() {
	return (
		<Stack as={Box} spacing={{ base: 5, md: 8 }}>
			<Heading as="h1" fontSize="3xl">
				Terms Of Use for Flowby AB
			</Heading>
			<Text as="i" style={{ marginTop: 2 }}>
				Version 1.2, last revised: 2024-02-08
			</Text>
			<VStack spacing={5} textAlign="left">
				<Text fontSize="xl" color={"gray.700"}>
					Thank you for choosing Flowby AB (559262-5866) as a service provider
					to your business (referred to as "You").
				</Text>
				<Text fontSize="xl" color={"gray.700"}>
					These terms of use contain the terms under which Flowby provides its
					service and how you gain access to and can use the service.
				</Text>
				<Text fontSize="xl" color={"gray.700"}>
					Flowby provides a digital service and queue management system. The
					service includes, but is not limited to, generation of digital queue
					tickets and an administrative interface in which the queue can be
					handled by You.
				</Text>
				<Text fontSize="xl" color={"gray.700"} w="100%">
					You agree to these terms by signing the agreement or by using the
					service.
				</Text>
				<Text fontSize="xl" color={"gray.700"}>
					If You are to use the Service on behalf of an organization, You agree
					to these terms on behalf of that organization and confirm that You are
					authorized to do so. In that case, "You" refers to that organization.
				</Text>
				<Text fontSize="xl" color={"gray.700"}>
					You specify the organization's information in the signup flow via our
					website or by emailing us. By using the Service, you agree to becoming
					a customer of Flowby, which could mean that we list your company under
					“our customers” on our website, Linkedin and the like, unless
					otherwise agreed upon.
				</Text>

				<Heading w="100%" as="h2" fontSize="2xl">
					1. Fees and payments
				</Heading>
				<OrderedList w="100%" pl={{ base: 4, md: 20 }} spacing={4}>
					<ListItem>
						<Text fontSize="xl" color={"gray.700"}>
							Fees for services. <br />
							You agree to pay Flowby's fee for the service you use, according
							to the prices and payment terms presented for the service. Unless
							agreed elsewhere (i.e. in written email), our prices are displayed
							on our website. You will be charged via the selected payment
							method, for example via invoice. Fees paid are non-refundable,
							except as provided in these Terms or as required by law. <br />
							The fee is fixed and charged per year. An additional variable cost
							may apply for the SMS your customers initiate which exceeds a
							certain amount and is invoiced separately the following month. You
							are informed about the SMS fees and amounts, if applicable.
						</Text>
					</ListItem>
					<ListItem>
						<Text fontSize="xl" color={"gray.700"}>
							Subscription. <br />
							Flowby's service is billed on a subscription basis. You are
							invoiced in advance on a recurring, periodic basis (each period is
							called a “billing cycle”). Billing cycles are normally years,
							depending on which subscription plan you choose when you sign up
							for a subscription. The subscription is automatically renewed at
							the end of each billing cycle unless you disable automatic renewal
							by contacting Flowby. If you do, the subscription continues until
							the end of the billing cycle and then ends.
						</Text>
					</ListItem>
					<ListItem>
						<Text fontSize="xl" color={"gray.700"}>
							Taxes. <br />
							Indicated prices are not including VAT.
						</Text>
					</ListItem>
					<ListItem>
						<Text fontSize="xl" color={"gray.700"}>
							Price changes. <br />
							Flowby may change the fees charged for the Service at any time,
							but for Service charged by subscription, the change will not take
							effect until the end of the then-current subscription billing
							cycle. Flowby will notify in good time in advance of any changes
							to the fees.
						</Text>
					</ListItem>
				</OrderedList>

				<Heading w="100%" as="h2" fontSize="2xl">
					2. Information management and security
				</Heading>
				<OrderedList w="100%" pl={{ base: 4, md: 20 }} spacing={4}>
					<ListItem>
						<Text fontSize="xl" color={"gray.700"}>
							Confidential Information. <br />
							Flowby treats your content as confidential information and it is
							used and disclosed only in accordance with these terms (including
							Flowby's privacy policy). However, your content does not
							constitute confidential information if the content in question:
							(a) is generally known or published; (b) was lawfully known to
							Flowby before it was obtained from you; (c) is obtained by Flowby
							from a third party without knowledge of the breach of an
							obligation owed to you; or (d) has been developed by Flowby
							independently and without reference to your Content. Flowby may
							disclose your content when required to do so by law or legal
							process, but only after Flowby, if permitted by law, has used
							commercially reasonable methods to notify you in order to give you
							the opportunity to contest the obligation to disclose the
							information.
						</Text>
					</ListItem>
					<ListItem>
						<Text fontSize="xl" color={"gray.700"}>
							Security. <br />
							Flowby stores and processes content in a manner consistent with
							industry security standards. Flowby has implemented appropriate
							technical, organizational and administrative systems, policies and
							procedures to promote the security, integrity and confidentiality
							of your content and to reduce the risk of unauthorized access to
							or use of content.
						</Text>
					</ListItem>
				</OrderedList>

				<Heading w="100%" as="h2" fontSize="2xl">
					3. Flowby IP
				</Heading>
				<OrderedList w="100%" pl={{ base: 4, md: 20 }} spacing={4}>
					<ListItem>
						<Text fontSize="xl" color={"gray.700"}>
							Flowby IP. <br />
							Neither these Terms nor use of the Services confer ownership
							rights to the Service or the Content that You access through the
							Service (other than Your Content). By using the Service, you agree
							to not re-sell any texts, images, design, code or data elements
							made available to you via the Service as is owned by Flowby.
							<br />
							If you send us feedback or suggestions regarding our service, we
							may use and share them for any purpose without compensation or
							obligation.
						</Text>
					</ListItem>
				</OrderedList>

				<Heading w="100%" as="h2" fontSize="2xl">
					4. Account management
				</Heading>
				<OrderedList w="100%" pl={{ base: 4, md: 20 }} spacing={4}>
					<ListItem>
						<Text fontSize="xl" color={"gray.700"}>
							Protect passwords. <br />
							If Flowby has issued an account in connection with use of the
							Service, you are responsible for protecting passwords and other
							information used to access that account. If you become aware of
							unauthorized account access, you must notify Flowby immediately.
						</Text>
					</ListItem>
					<ListItem>
						<Text fontSize="xl" color={"gray.700"}>
							Keep information up to date. <br />
							Flowby sometimes sends messages to the email address registered
							with the account. You must keep the email address and, where
							applicable, the contact information and payment information
							associated with the account up to date.
						</Text>
					</ListItem>
					<ListItem>
						<Text fontSize="xl" color={"gray.700"}>
							Inactive account. <br />
							Flowby can terminate the account and remove any content on the
							account if payment is not made after 3 months. However, we will
							warn you by e-mail before we cancel the account, so that you have
							the opportunity to pay so that it remains active.
						</Text>
					</ListItem>
					<ListItem>
						<Text fontSize="xl" color={"gray.700"}>
							Customer service. <br />
							You may contact our customer service at any time and we will try
							to answer and help you as soon as possible. However, we make no
							guarantee in this regard. Read more about this in the Disclaimer
							section.
							<br />
							Contact information: hello@flowby.io
						</Text>
					</ListItem>
				</OrderedList>

				<Heading w="100%" as="h2" fontSize="2xl">
					5. User requirements
				</Heading>
				<OrderedList w="100%" pl={{ base: 4, md: 20 }} spacing={4}>
					<ListItem>
						<Text fontSize="xl" color={"gray.700"}>
							Legal person. <br />
							By using the Services, you confirm that you represent a validly
							formed organization existing under the laws of the Jurisdiction,
							that you have full authority and permission to enter into an
							agreement with these Terms and that you have duly authorized
							representatives to be bound by these Terms.
						</Text>
					</ListItem>
				</OrderedList>

				<Heading w="100%" as="h2" fontSize="2xl">
					6. Suspension and Termination of Services
				</Heading>
				<OrderedList w="100%" pl={{ base: 4, md: 20 }} spacing={4}>
					<ListItem>
						<Text fontSize="xl" color={"gray.700"}>
							By you. <br />
							You can cancel your subscription and delete your account at any
							time by contacting Flowby. Such termination and deletion will
							result in the account being disabled or closed and you will no
							longer be able to access the account, and the content collected
							through use of the Service will be deleted. The cancellation is
							confirmed immediately and you will not be charged again for the
							subscription unless you purchase a new subscription. If You cancel
							a Subscription in the middle of a billing cycle, You will not
							receive a refund, unless You cancel for any of the following
							reasons: (a) we have committed a material breach of these Terms
							and have failed to cure the breach within 30 days of You having
							notified us in writing; or (b) a refund is required by law; or (c)
							Flowby decides in its sole discretion that a refund is
							appropriate. Flowby clarifies that we will not provide a refund in
							cases where you have used our service, unless the termination is
							due to our material, to unresolved infringement or if a refund is
							required by law.
						</Text>
					</ListItem>
					<ListItem>
						<Text fontSize="xl" color={"gray.700"}>
							By Flowby. <br />
							Flowby may terminate the subscription at the end of a billing
							cycle by giving at least 30 days prior written notice. Flowby may
							terminate subscription for any reason by giving at least 90 days
							prior written notice and will provide a pro rata refund for the
							unused portion of the time in the billing cycle. Flowby may
							suspend performance of its obligations or terminate subscription
							for any of the following reasons: (a) You have committed a
							material breach of these Terms and failed to remedy the breach
							within 30 days of Flowby notifying you in writing; (b) You fail to
							pay the fees for 30 days after the due date. In addition, Flowby
							may limit, suspend, or terminate the Services You subscribe to:
							(i) if You do not comply with the provisions of these Terms, or
							(ii) if We are investigating suspected misconduct on Your part. If
							we limit, suspend or terminate the Services, we will try to
							provide advance notice. However, there may be time-sensitive
							situations when Flowby may decide that we need to take immediate
							action without notice. Flowby has no obligation to preserve
							content after the termination of the service in question.
						</Text>
					</ListItem>
				</OrderedList>

				<Heading w="100%" as="h2" fontSize="2xl">
					7. Changes and Updates
				</Heading>
				<OrderedList w="100%" pl={{ base: 4, md: 20 }} spacing={4}>
					<ListItem>
						<Text fontSize="xl" color={"gray.700"}>
							Changes to Terms. <br />
							Flowby may change these terms at any time for various reasons, for
							example to reflect changes in applicable law or updates to
							services, as well as to adapt to new services or new features. The
							latest version will always be available on the Flowby website
							(https://flowby.io). If Flowby considers an addition to be
							essential, Flowby will notify you via email. Changes come into
							effect at the earliest on the day they are posted publicly. If You
							do not wish to agree to any changes to the Terms regarding a
							Service, You should cease using that Service, as continued use of
							the Service indicates agreement to be bound by the updated Terms.
						</Text>
					</ListItem>
					<ListItem>
						<Text fontSize="xl" color={"gray.700"}>
							Changes to Service. <br />
							Flowby changes and improves the service continuously. Flowby may
							add, change or remove features from a Service at any time without
							prior notice. Flowby may also limit, suspend or terminate a
							service at its sole discretion. If Flowby terminates a service, we
							will provide advance notice within a reasonable time.
						</Text>
					</ListItem>
				</OrderedList>

				<Heading w="100%" as="h2" fontSize="2xl">
					8. Disclaimer and Limitation of Liability
				</Heading>
				<OrderedList w="100%" pl={{ base: 4, md: 20 }} spacing={4}>
					<ListItem>
						<Text fontSize="xl" color={"gray.700"}>
							Disclaimer. <br />
							While it's in Flowby's best interest to provide the best possible
							experience (and we really try to make our customers happy), there
							are some things we don't promise. We try to keep our online
							services operational, but they may be unavailable in exceptional
							circumstances. Except as expressly provided in these Terms and to
							the extent permitted by law, the Services are provided as is and
							Flowby makes no warranties of any kind, whether express, implied
							or statutory, including warranties of merchantability, fitness for
							a particular purpose and absence of infringement of third party
							rights, or any representations regarding the availability,
							reliability or accuracy of the Services.
						</Text>
					</ListItem>
					<ListItem>
						<Text fontSize="xl" color={"gray.700"}>
							Exclusion from certain liability. <br />
							To the extent permitted by applicable law, Flowby, its employees,
							agents, suppliers and licensors will not be liable for (a) any
							indirect, consequential, special, incidental or punitive damages,
							or (b) loss of usability or data, loss of business, revenue or
							profits (in each case whether directly or indirectly), arising out
							of or in connection with the Service and its Terms, and whether
							based on contract, tort, strict liability or any other legal
							theory, even if Flowby has been advised of the possibility of such
							damages and even if the essential purpose of the remedy fails.
						</Text>
					</ListItem>
					<ListItem>
						<Text fontSize="xl" color={"gray.700"}>
							Limitation of Liability. <br />
							To the extent permitted by applicable law, the aggregate liability
							of Flowby, its employees, agents, suppliers and licensors arising
							out of or in connection with the Service and its Terms shall not
							exceed the lesser of the amounts paid to Flowby for use of the
							Service within a period of 12 months before the event giving rise
							to the liability.
						</Text>
					</ListItem>
					<ListItem>
						<Text fontSize="xl" color={"gray.700"}>
							Compensation obligation. <br />
							If You conduct business, You shall indemnify Flowby and its
							subsidiaries, officers, agents and employees from all liabilities,
							claims and costs (including settlement costs and reasonable
							attorneys' fees) (“Indemnities”) arising out of third party claims
							relating to You or Your End Users' use of the Service or breach of
							these Terms, to the extent these obligations, claims and costs are
							caused by you or your end users.
						</Text>
					</ListItem>
				</OrderedList>

				<Heading w="100%" as="h2" fontSize="2xl">
					9. Other conditions
				</Heading>
				<OrderedList w="100%" pl={{ base: 4, md: 20 }} spacing={4}>
					<ListItem>
						<Text fontSize="xl" color={"gray.700"}>
							Transfer. <br />
							These Terms may not be assigned without Flowby's written consent,
							and Flowby may in its sole discretion refuse to permit an
							assignment. Flowby may assign these Terms at any time without
							notice to you.
						</Text>
					</ListItem>
					<ListItem>
						<Text fontSize="xl" color={"gray.700"}>
							The entire agreement. <br />
							These Terms constitute the entire agreement between you and
							Flowby, and supersede all prior or contemporaneous written and
							oral agreements and terms relating to the subject matter.
						</Text>
					</ListItem>
					<ListItem>
						<Text fontSize="xl" color={"gray.700"}>
							Independent suppliers. <br />
							The relationship between you and Flowby is as independent
							suppliers, and not as legal partners, employees or agents for each
							other.
						</Text>
					</ListItem>
					<ListItem>
						<Text fontSize="xl" color={"gray.700"}>
							Invalidity of individual contractual provisions. <br />
							If any part of these terms and conditions is deemed unenforceable
							by a court of competent jurisdiction, that part shall be deemed
							severable from the rest of the terms and conditions, which shall
							remain in full force and effect.
						</Text>
					</ListItem>
					<ListItem>
						<Text fontSize="xl" color={"gray.700"}>
							Third party as beneficiary. <br />
							There are no third party beneficiaries to these Terms.
						</Text>
					</ListItem>
				</OrderedList>
				<br />
				<Text w="100%" fontSize="xl" color={"gray.700"}>
					Flowby AB <br />
					Bältgatan 6 <br />
					114 59 Stockholm <br />
					Sweden <br />
					Organizational number: 559262-5866 <br />
					Contact person: Susanne Tedsjö <br />
					Phone: +46 8519 424 09 <br />
					hello@flowby.io <br />
					https://flowby.io
				</Text>
			</VStack>
		</Stack>
	);
}
